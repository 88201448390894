.countdown {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100vh;
    background-color: #111;
}

.countdown .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 1000px;
}

.countdown .content h1 {
    width: 100%;
    text-align: center;
}

.countdown .content .counter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.countdown .content .counter .container {
    background-color: grey;
    height: 136px;
    width: 85px;
    margin: 10px;
    text-align: center;
    border-radius: 10%;
    position: relative;
}

.countdown .content .counter .container .center p {
    font-size: 20px;
}

@media (max-width: 1050px) {
    body {
        background-color: #111;
    }

    .countdown .content {
        display: block;
        overflow: hidden;
        width: 100vw;
        text-align: center;
    }

    .vl {
        display: none;
    }
}