.SMP {
	margin: 25px;
}

.SMP .sortOptions {
	width: 100%;
	height: 30px;
}

.SMP .sortOptions p {
	margin: 0 0 0 0;
	float: left;
	font-size: 20px;
}

.SMP .sortOptions button {
	float: left;
	margin-left: 10px;

	width: 30px;
	height: 30px;

	background-color: transparent;
	border-color: #aaa;
	border-width: 1px;
	border-style: solid;
	box-shadow: none;
	color: white;

	cursor: pointer;

	transition: all 0.1s ease-in-out;
	border-radius: 5px;

	appearance: none;
    -moz-appearance: none;
	-webkit-appearance: none;
}

.SMP .sortOptions button:hover {
	background-color: #fff;
	color: black;
}

.SMP .sortOptions button:active {
	background-color: #AAA;
	color: black;
}

.SMP .sortOptions button.selected {
	background-color: #222;
	border-color: #f9cb26;
	color: white;
}

.SMP .sortOptions button.selected:hover {
	background-color: #AAA;
	color: black;
}

.SMP .suggestions-list {
	margin-top: 50px;
    height: calc(100vh - 250px);
    overflow-y: scroll;
    padding-right: 20px;
}

.SMP .suggestions-list .suggestion {
	position: relative;
	background-color: #222;
	border-radius: 10px;
	height: 220px;
    margin-bottom: 25px;
}

.SMP .suggestions-list .suggestion .l-panel {
	float: left;
	padding: 10px 0px 10px 14px;
	width: calc(50% - 14px);
}

.SMP .suggestions-list .suggestion .r-panel {
	float: right;
	padding: 10px 14px 10px 0px;
	width: calc(50% - 14px);
}

.SMP .suggestions-list .suggestion h3 {
	margin: 0;
	margin-bottom: 10px;
}

.SMP .suggestions-list .suggestion .var {
	margin: 0;
	margin-bottom: 10px;
	max-height: 60px;
}

.SMP .suggestions-list .suggestion .r-panel .var {
    text-align: right;
}

.SMP .suggestions-list .suggestion .var p {
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}

.SMP .suggestions-list .suggestion .var .head {
	text-transform: capitalize;
	font-weight: 800;
	margin-bottom: 2px;
	font-size: 13px;
}

.SMP .suggestions-list .suggestion .PrioityLevel {
    position: relative;
    margin-left: auto;
    margin-right: 0;
    border-radius: 5px;
    border-color: white;
    border-width: 1px;
    border-style: solid;
    width: 30px;
	height: 30px;
    margin-bottom: 6px;
}

.SMP .suggestions-list .suggestion .PrioityLevel p {
    position: absolute;
    top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
    margin: 0;
    width: 100%;
    text-align: center;
}

.SMP .suggestions-list .suggestion .buttons {
	position: absolute;
	bottom: 15px;
	left: 20px;
}

.SMP .suggestions-list .suggestion .buttons button {
	margin: 0;
	border: none;
	border-radius: 5px;
	padding: 5px 10px;
	color: black;
	font-family: "Michroma";

	box-shadow: 4px 6px 7px -2px rgba(0, 0, 0, 0.41);
	-webkit-box-shadow: 4px 6px 7px -2px rgba(0, 0, 0, 0.41);
	-moz-box-shadow: 4px 6px 7px -2px rgba(0, 0, 0, 0.41);

    cursor: pointer;
}

.SMP .suggestions-list .suggestion .buttons button.approve {
	background-color: #7ac142;
}

.SMP .suggestions-list .suggestion .buttons button.reject {
	background-color: #d43c5d;
}

.SMP .suggestions-list .suggestion .buttons button.more {
	background-color: #eeeb44;
}
