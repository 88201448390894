#AdminPanel form.Login {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 500px;

	padding: 50px;
	background-color: #222;
	border-radius: 20px;
	box-shadow: 0px 10px 13px -7px #000000, 15px 20px 50px 2px rgba(0, 0, 0, 0.31);
}

#AdminPanel form.Login h1 {
	margin-top: 0px;
	text-align: center;
}

#AdminPanel form.Login input[type="text"],
#AdminPanel form.Login input[type="password"] {
	width: calc(100% - 40px);
	padding: 12px 20px;
	margin: 10px 20px 20px 20px;
	box-sizing: border-box;
	border: 2px solid #ccc;
	border-radius: 4px;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	outline: none;
	cursor: text;
}

#AdminPanel form.Login input[type="submit"] {
	padding: 8px 0px;
	width: calc(100% - 40px);
	margin: 10px 20px 10px 20px;

	font-family: "Michroma";
	font-size: 20px;
	font-weight: bold;

	background-color: #4caf50;
	color: white;

	border: 0px;
	border-radius: 6px;
	cursor: pointer;
	overflow: visible;
	white-space: normal;
}

#AdminPanel form.Login label {
	margin-left: 20px;
}
