#AdminPanel {
	position: relative;
	width: 100%;
	height: 100%;
}

#AdminPanel .SidePanel {
	float: left;
	height: 100%;
	width: 400px;

	align-items: center;

	background-color: #222;
}

#AdminPanel .SidePanel h1 {
	margin-bottom: 10px;
	text-align: center;
}

#AdminPanel .SidePanel div.user {
	padding-left: 40px;
	padding-right: 25px;
	vertical-align: middle;
	height: 20px;
}

#AdminPanel .SidePanel div.user p {
	float: right;
	margin: 0;
}

#AdminPanel .SidePanel div.user svg {
	float: right;
	font-size: 22px;
	padding-left: 15px;
}


#AdminPanel .SidePanel a {
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 50px;
	margin: 0;
	display:block;
	text-decoration: none;
	color:white;
}

#AdminPanel .SidePanel a:hover {
	background-color: #333;
}

#AdminPanel .Content {
	float: left;
	width: calc(100% - 400px);
	min-height: 100vh;
}