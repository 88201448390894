@font-face {
	font-family: "Michroma";
	src: url("./fonts/Michroma/Michroma-Regular.ttf");
}

body {
	padding: 0;
	margin: 0;
	font-family: "Michroma";
	background-color: #333;
	color: white;
}

div.App {
	position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
	overflow: auto;
}

#portal {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 2;
	pointer-events: none;
}

div.center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

nav {
	height: 45px;
	width: 100%;
	background-color: #333;
	overflow: hidden;
}

nav div.title {
	float: left;
	width: 30%;
	height: 100%;
}

nav div.title h1 {
	margin: 0;
	padding: 0;
	padding-top: 10px;
	padding-right: 100px;

	float: right;
	text-align: center;
}

nav div.title h1 a {
	color: white;
	text-decoration: none;
	font-weight: 100;
}

nav div.nav-links {
	float: left;
	width: 65%;
	height: 100%;
}

nav div.nav-links ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

nav div.nav-links ul li {
	float: left;
	transition: 0.2s ease;
}

nav div.nav-links ul li a {
	transition: 0.2s ease;

	float: left;
	color: #f2f2f2;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	font-size: 17px;
}

nav div.nav-links ul li:hover a {
	background-color: #ddd;
	color: black;
}

nav div.social {
	float: left;
	width: 5%;
	height: 100%;
}

nav div.social ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

nav div.social ul li {
	float: left;
	transition: 0.2s ease;
}

nav div.social ul li a {
	transition: 0.2s ease;

	float: left;
	color: #f2f2f2;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	font-size: 17px;
}

nav div.social ul li:hover a {
	background-color: #ddd;
	color: black;
}

/*******************/
/*******Modal*******/
/*******************/

div.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #222;
	padding: 25px;
	z-index: 10000;
	color: white;
	pointer-events: all;
	border-radius: 10px;
	border-width: 0px;
	border-color: rgba(0, 0, 0, 0);
}

div.modal input[type="button"] {
	padding: 8px 0px;
	width: calc(100% - 40px);
	margin: 30px 20px 10px 20px;

	font-family: "Michroma";
	font-size: 20px;
	font-weight: bold;

	background-color: #4caf50;
	color: white;

	border: 0px;
	border-radius: 6px;
	cursor: pointer;
	overflow: visible;
	white-space: normal;
}

div.modalOverlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 9999;
	pointer-events: all;
}

/*******************/
/*****Error 404*****/
/*******************/

div.E404 {
	padding: 40px;
}

div.E404 a {
	color: cyan;
}

div#footer {
	width: 100%;
	height: 100px;
	border-top: 5px solid #f9cb26;
	display: flex;
  	align-items: center;
  	justify-content: center;
	overflow: hidden
}

div#footer #copyright {
	padding-right: 0px;
}

div#footer #other .digitalOcean svg {
	height: 45px;
	padding-right: 15px;
}

div #footer #other .coffee img {
	height: 45px;
	width: 162.75px;
}