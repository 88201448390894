.cross__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #d43c5d;
    fill: none;
    animation: crossStroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .cross {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 0px auto;
    box-shadow: inset 0px 0px 0px #d43c5d;
    animation: crossFill .4s ease-in-out .4s forwards, crossScale .3s ease-in-out .9s both;
  }
  
  .cross__line {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: crossStroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  .cross__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: crossStroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes crossStroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes crossScale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes crossFill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #d43c5d;
    }
  }