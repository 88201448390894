.ehshs {
	padding: 0px;
	margin: 0px;
	width: 100%;
	height: 100%;
	background-color: #111;
}

.ehshs .content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 1000px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ehshs .content .logos {
	width: 200px;
	padding-right: 25px;
}

.ehshs .content .logos .logo {
	height: 200px;
	width: 200px;
	float: left;
	fill: #f9cb25;
	overflow: visible !important;
}

.ehshs .content .logos .logo .cls-1 {
	fill: #f9cb25;
}

.ehshs .content .logos .school {
	padding-top: 25px;
	height: 225px;
	width: 200px;
}

.ehshs .content .survey {
	height: 700px;
	width: 800px;
	padding-left: 10px;
	overflow-y: scroll;
}

.ehshs .content .survey h1 {
	margin: 0px 0px 25px 0px;
	text-align: center;
}

.ehshs .content .survey h3 {
	margin: 10px 0px 10px 0px;
}

.ehshs .content .survey p {
	text-align: center;
}

.ehshs .content .survey p a {
	color: #f9cb26;
}

.ehshs .content .survey input[type="text"],
.ehshs .content .survey input[type="email"] {
	width: calc(100% - 40px);
	padding: 12px 20px;
	margin: 8px 20px 8px 20px;
	box-sizing: border-box;
	border: 2px solid #ccc;
	border-radius: 4px;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	outline: none;
	cursor: text;
}

.ehshs .content .survey input[type="email"] {
	width: calc(100% - 20px);
	margin: 8px 0px 8px 20px;
}

.ehshs .content .survey input[type="text"]:focus,
.ehshs .content .survey input[type="email"]:focus {
	border: 2px solid #555;
}

.ehshs .content .survey label {
	padding-left: 20px;
	cursor: pointer;
}

.ehshs .content .survey input[type="checkbox"] {
	cursor: pointer;
}

.ehshs .content .survey input[type="button"] {
	padding: 8px 0px;
	width: calc(100% - 40px);
	margin: 10px 20px;

	font-family: "Michroma";
	font-size: 20px;
	font-weight: bold;

	background-color: #4caf50;
	color: white;

	border: 0px;
	border-radius: 6px;
	cursor: pointer;
	overflow: visible;
	white-space: normal;
	
	appearance: none;
    -moz-appearance: none;
	-webkit-appearance: none;
}

.ehshs .content .survey select {
	width: calc(100% - 40px);
	padding: 12px 20px;
	margin: 8px 20px;
	border: 2px solid #ccc;
	border-radius: 4px;
	cursor: pointer;
}

.table-info {
	width: 100%;
	height: 25px;
}

.table-info h3 {
	font-size: 1em;
	float: left;
	margin: 0 !important;
}

.table-info #votesleft {
	float: right;
	padding-right: 20px;
    margin: 0;
}

.Table-Loading {
	background-color: #222;
	margin: 20px;
	border-radius: 25px;
	height: 325px;
	width: calc(100% - 40px);
	position: relative;
}

.Table-Loading .loading-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0;
}

.loading-spinner {
	position: relative;
	margin-left: calc(50% - 75px / 2);
	width: 75px;
	height: 75px;
}

.loading-spinner .spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#table-wrapper {
	position: relative;
	width: calc(100% - 40px);
	margin: 8px 20px;
}

#table-scroll {
	height: 325px;
	overflow: auto;
	margin-top: 20px;
}

#table-scroll table {
	width: 100%;
}

#table-scroll table tbody {
	width: 100%;
	overflow: auto;
	height: 164px;
}

#table-scroll table th {
	height: 46px;
}

#table-wrapper table thead {
	height: 56px;
}

#table-wrapper table thead th .text {
	position: absolute !important;
	top: 0px;
	z-index: 2;
	height: 56px;
	width: 120px;
	width: inherit;
	padding: 5px;
	background: black;
	transform: translateX(-50%);
}

#table-scroll table thead {
	background: black;
	color: #fff;
}

#table-scroll table th,
table td {
	padding: 5px;
	text-align: center;
}

#table-scroll table tbody tr {
	background-color: #222222;
}

#table-scroll table tbody tr:hover {
	background-color: #ccc;
	color: black;
	cursor: pointer;
}

#table-scroll table tbody tr.voted {
	background-color: grey;
}

#table-scroll table tr td {
	text-align: center;
	padding: 7px 10px;
}

#table-scroll table tr td {
	min-width: 100px !important;
}

div.personal {
	padding-left: 20px;
}

div.personal p {
	text-align: left !important;
}

div.personal .checkboxes {
    margin-left: 20px;
}

div.personal .checkboxes label{
    margin-left: 0px;
}

div.personal .info {
    margin-top: 20px;
	height: 82px;
	display: flex;
	justify-content: left;
	align-items: center;
}

div.personal .info .year {
	width: 20%;
}

div.personal .info .email {
	width: 80%;
}

div.personal .info .email.full {
	width: 100%;
}

div.personal .disclaimer {
    margin-left: 25px;
}

div.personal .info .email.full input[type="email"] {
	width: calc(100% - 40px);
	margin-right: 20px;
}

div.personal input[type="checkbox"] {
	margin-left: "25px";
}

p {
	font-size: 14px;
}

.vl {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 25px;
	height: 500px;
	width: 2px;
}

.redtext {
	color: red;
}

.yellowtext {
	color: yellow;
}

div.modal {
	width: 500px;
	text-align: center;
}

div.modal h1 {
	width: 100%;
	margin-top: 0px;
	margin-bottom: 0px;
}

div.modal p.subheader {
	margin-top: 5px;
	margin-bottom: 15px;
}

div.modal p {
	margin: 5px;
}

div.modal .loading-spinner {
	margin-bottom: 21.440px;
}

@media (max-width: 1050px) {
	body {
		background-color: #111;
	}

	.ehshs .content {
		display: block;
		overflow: hidden;
		width: 100vw;
		text-align: center;
		height: 100vh;
	}

	.ehshs .content .logos {
		padding-top: 50px;
		width: 100vw;
		height: 150px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	#table-scroll table thead .suggestedBy {
		display: none;
	}

	#table-scroll table tbody .suggestedBy {
		display: none;
	}

	.ehshs .content .logos .logo {
		width: 150px;
	}

	.ehshs .content .survey {
		width: calc(100vw - 50px);
		margin: 10px 0px 25px 25px;
		padding: 0px;
		height: calc(100vh - 250px);
	}

	div.personal {
		padding-left: 0px;
        text-align: left;
	}

    div.personal h3 {
        text-align: center;
    }

	div.personal .checkboxes {
		margin-left: 10px;
	}

    div.personal .info {
        margin-top: 10px;
    }

	.table-info h3 {
		font-size: 1em;
		float: initial;
		margin: 0;
	}

	.table-info {
		height: 50px;
	}

	.table-info #votesleft {
		float: initial;
        padding-top: 5px;
		padding-right: 25px;
		text-align: right;
	}

    .ehshs .content .survey label {
        margin-left: 5px;
        padding-left: 0px;
    }

    .ehshs .content .survey input[type="email"] {
        margin-left: 5px;
    }

    div.personal .info .email {
        width: 72%;
    }

    div.personal .info .year {
        width: 28%;
    }

    div.personal .disclaimer {
        margin-left: 5px;
    }

    .ehshs .content .survey select {
        width: calc(100% - 10px);
        margin-left: 5px;
        margin-right: 5px;
    }

	#table-scroll {
		width: 100%;
		margin-top: 10px;
	}

	.vl {
		display: none;
	}
}
